import React from "react";
import { Link } from "react-router-dom";

const Questions = () => {
  return (
    <div className="SecondStepFaqBlock__Wrapper-sc-14wpqp4-0 eTqVlq">
      <div className="sc-jOrMOR iYETnb" color="#141A2E">
        Have any questions?
      </div>
      <div className="SecondStepFaqBlock__QuestionWrapper-sc-14wpqp4-1 inMJWy">
        <div className="styles__Wrapper-sc-1mvpouc-0 dfLkbn">
          <div className="styles__Head-sc-1mvpouc-2 dcEZLZ">
            <div className="styles__Question-sc-1mvpouc-1 vqEUE">
              What is the recipient's address and where do I get it?
            </div>
            <div className="styles__Arrow-sc-1mvpouc-3 emhHNN" />
          </div>
          <div className="styles__Body-sc-1mvpouc-4 bgDJdE">
            <p>
              You can find the cryptocurrency address in the crypto wallet that
              you use to keep your coins and tokens. It contains letters and
              numbers and looks like an alphanumeric string. Cryptocurrencies
              are based on different blockchains and have their own unique
              address formats. It’s necessary to provide us with the crypto
              wallet address, and we’ll send your coins there.
            </p>
          </div>
        </div>
        <div className="styles__Wrapper-sc-1mvpouc-0 dfLkbn">
          <div className="styles__Head-sc-1mvpouc-2 dcEZLZ">
            <div className="styles__Question-sc-1mvpouc-1 vqEUE">
              Why is my recipient address shown as invalid?
            </div>
            <div className="styles__Arrow-sc-1mvpouc-3 emhHNN" />
          </div>
          <div className="styles__Body-sc-1mvpouc-4 bgDJdE">
            <p>There may be various reasons, including:</p>
            <ul>
              <li>
                <b>The spelling of the address.</b> Make sure that the address
                contains all the necessary auxiliary characters, suffixes, and
                prefixes (dots, dashes).
              </li>
              <li>
                <b>Extra spaces.</b> Make sure all address characters have been
                copied. Сheck there are no spaces at the beginning and end of
                the address.
              </li>
              <li>
                <b>Incorrect blockchain.</b> Make sure that the network of the
                selected coin matches the network your address belongs to.
              </li>
              <li>
                <b>Some other issues.</b> Feel free to contact our support team
                which is available 24/7 via email{" "}
                <Link to="/exchange" target="_blanc">
                  (support@simpleswap.io)
                </Link>{" "}
                or live chat.
              </li>
            </ul>
          </div>
        </div>
        <div className="styles__Wrapper-sc-1mvpouc-0 dfLkbn">
          <div className="styles__Head-sc-1mvpouc-2 dcEZLZ">
            <div className="styles__Question-sc-1mvpouc-1 vqEUE">
              How do I get cashback for the exchange on SimpleSwap?
            </div>
            <div className="styles__Arrow-sc-1mvpouc-3 emhHNN" />
          </div>
          <div className="styles__Body-sc-1mvpouc-4 bgDJdE">
            <p>
              Log in to your account, swap crypto, and receive cashback for
              every exchange. If you still don’t have a customer account on
              SimpleSwap, you are welcome to{" "}
              <Link
                to="/exchange"
                target="_blanc"
              >
                sign up!
              </Link>
            </p>
            <p>
              Don’t forget to log in to your account every time before creating
              the exchange as this is the only way to get crypto cashback.
              Please, read about different types of cashback subscriptions and
              find the terms and conditions of our Loyalty Program{" "}
              <Link to="/exchange" target="_blanc">
                here.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
